import Logo from "./components/Logo";
import './App.scss';

function App() {
  return (
    <div className="App">
      <div className="logo">
          <header>
              <Logo/>
          </header>
          <article>
              <p>Nowa odsłona naszej strony już wkrótce. <br/>Zapraszamy!</p>
          </article>
          <footer>
              <div className="address">
                  <p>
                      <span>Base One sp. z o.o.</span>
                      ul. Emaus 19<br/>
                      30-213 Kraków<br/>
                      NIP: 677 246 55 70<br/>
                  </p>
              </div>
              <div className="team">
                  <p>
                      <span>Paweł</span>
                      <a href="mailto:pawel@baseone.pl">pawel@baseone.pl</a><br/>
                      <a href="tel:0048533991544">+48 533 991 544</a>
                  </p>
                  <p>
                      <span>Aleks</span>
                      <a href="mailto:aleks@baseone.pl">aleks@baseone.pl</a><br/>
                      <a href="tel:0048694575452">+48 694 575 452</a>
                  </p>
                  <p>
                      <span>Mirek</span>
                      <a href="mailto:mirek@baseone.pl">mirek@baseone.pl</a><br/>
                      <a href=""></a>
                  </p>
              </div>
          </footer>
      </div>
    </div>
  );
}

export default App;
